.WorkItem {
  position: relative;
  background-color: #fff;
  padding-bottom: 4rem;
  margin: 1rem 0;
  border-radius: 6px;
  overflow: hidden;
}

.WorkItem-image {
  padding: 0.5rem;
}

.WorkItem:nth-of-type(4n + 1) .WorkItem-image {
  background-color: #8fbcbb;
}
.WorkItem:nth-of-type(4n + 2) .WorkItem-image {
  background-color: #a3be8b;
}
.WorkItem:nth-of-type(4n + 3) .WorkItem-image {
  background-color: #b48dac;
}
.WorkItem:nth-of-type(4n) .WorkItem-image {
  background-color: #eacb8a;
}

.WorkItem-image > img {
  width: 100%;
}

.WorkItem-description {
  white-space: pre-line;
}

.WorkItem-title,
.WorkItem-description {
  padding: 0 1rem;
}

.WorkItem-links {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  list-style: none;
  display: flex;
  margin: 0 0 1rem;
  padding: 0;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.WorkItemLink-emoji {
  font-family: 'Apple Color Emoji', 'Segoe UI', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 1.2em;
  vertical-align: middle;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1);
}

.WorkItemLink-emoji,
.WorkItemLink-icon {
  margin-right: 8px;
}

@media (min-width: 1008px) {
  .WorkItem {
    position: static;
    width: 48%;
    flex: 1 1 40%;
    margin: 0.75rem;
    padding-bottom: 1rem;
  }

  .WorkItem:nth-child(odd) {
    margin-left: 0;
  }

  .WorkItem:nth-child(even) {
    margin-right: 0;
  }

  .WorkItem-image {
    position: relative;
  }

  .WorkItem-links {
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    height: 100%;
    transition: opacity 0.3s ease-in;
  }

  .WorkItem:hover .WorkItem-links,
  .WorkItem .WorkItem-links:focus-within {
    opacity: 1;
  }

  .WorkItemLink {
    font-weight: normal;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    border-color: rgba(255, 255, 255, 0.8);
  }

  .WorkItemLink:hover,
  .WorkItemLink:focus,
  .WorkItemLink:active {
    font-weight: bold;
    color: #333;
    background-color: #fff;
    border-color: #333;
  }
}
