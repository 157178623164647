.Footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 600px) {
  .Footer {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
