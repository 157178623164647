html {
  font-size: 1em;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1rem;
  line-height: 1.5;
  color: #404040;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
  border-bottom: 1px solid #404040;
}

a:hover,
a:active,
a:focus {
  color: #000;
  border-color: #000;
}

@media (min-width: 600px) {
  body {
    font-size: 1.125rem;
  }
}
