.ContactForm {
  width: 100%;
  max-width: 690px;
  margin: 0 auto;
  padding-bottom: 1rem;
}

.ContactForm-label {
  display: block;
  width: 100%;
}

.ContactForm-field,
.ContactForm-body {
  display: block;
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0 1rem;
  border: solid 2px #bbb;
  font-family: inherit;
  font-size: 1em;
  transition: border-color 0.1s ease-in;
}

.ContactForm-body {
  min-height: 10rem;
  max-width: 100%;
}

.ContactForm-field:hover,
.ContactForm-field:active,
.ContactForm-field:focus,
.ContactForm-body:hover,
.ContactForm-body:active,
.ContactForm-body:focus {
  border-color: #999;
}

.ContactForm-submit {
  margin: 2rem auto 1rem;
}

.ContactForm-submit-emoji {
  font-family: 'Apple Color Emoji', 'Segoe UI', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 1.2em;
  vertical-align: middle;
  margin-right: 8px;
}

.ContactForm-message {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  margin: 0;
  padding: 1rem;
  text-align: center;
  opacity: 1;
  transform: scaleY(1);
  transform-origin: bottom;
  transition: opacity 0.2s ease-in, transform 0.2s ease-in,
    background-color 0.2s ease-in;
}

.ContactForm-message-idle {
  opacity: 0;
  transform: scaleY(0);
}

.ContactForm-message-success {
  background-color: #d0efb5;
}

.ContactForm-message-failure {
  background-color: #fbafaf;
}
