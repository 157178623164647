.Navbar {
  position: relative;
  height: 3.6875rem;
  background-color: #fff;
}

.Navbar-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
}

.Navbar-buttons {
  display: flex;
  justify-content: space-between;
}

.Navbar-logo {
  display: block;
  border-radius: 50%;
  font-family: Inconsolata, Menlo, Consolas, monospace;
  font-weight: bold;
  text-align: center;
  justify-self: start;
}

.Navbar-nav {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: opacity 0.2s ease-in, transform 0.3s ease-in;
}

.Navbar-nav-expanded {
  opacity: 1;
  transform: scaleY(1);
}

.Navbar-list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.Navbar-link {
  display: block;
}

.Navbar-link > a {
  display: block;
  border: 0;
  padding: 1rem 0.5rem;
  color: #333;
  font-weight: normal;
}

.Navbar-link > a:active,
.Navbar-link > a:hover {
  background-color: #eee;
}

.Navbar-link-active > a {
  font-weight: bold;
}

.Navbar-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.25rem;
  width: 2.25rem;
  height: 2.25rem;
  margin: 0.718rem 0;
  border: solid 1px transparent;
  color: #fff;
  background-color: #333;
  transition: background-color 0.3s ease-in;
}

.Navbar-btn:hover,
.Navbar-btn:active,
.Navbar-btn:focus {
  background-color: #fff;
  color: #333;
  border: solid 2px #333;
  outline: 0;
}

.Navbar-toggler {
  font-size: 1.25rem;
  line-height: 1;
  font-family: inherit;
  border: none;
  cursor: pointer;
  justify-self: end;
}

@media (min-width: 400px) {
  .Navbar-link > a {
    padding: 1rem;
  }
}

@media (min-width: 600px) {
  .Navbar-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .Navbar-nav {
    position: static;
    width: auto;
    opacity: 1;
    transform: none;
    box-shadow: none;
    overflow-y: hidden;
    transition: none;
  }

  .Navbar-list {
    flex-direction: row;
    justify-content: flex-end;
  }

  .Navbar-link > a {
    position: relative;
  }

  .Navbar-link > a::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #333;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    transition: transform 0.4s ease;
  }

  .Navbar-link > a:active,
  .Navbar-link > a:hover {
    background-color: unset;
    /* border-bottom: solid 2px #333; */
  }

  .Navbar-link > a:hover::after,
  .Navbar-link > a:active::after {
    transform: translateY(0);
  }

  .Navbar-link-active > a::after {
    /* border-bottom: solid 2px #333; */
    transform: translateY(0);
  }
}
