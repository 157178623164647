.Work {
  background-color: #f7e1d3;
}

.Work-items {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1008px) {
  .Work-items {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 1rem 0;
  }
}
