.Cover {
  height: 100vh;
  padding-top: 3.375rem;
  display: flex;
}

.Cover-heading {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-weight: normal;
  font-size: 2rem;
  text-transform: lowercase;
}
.Cover-heading::before {
  content: '{';
}
.Cover-heading::after {
  content: '}';
}

.Cover-lead {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.3em;
}

.Cover-line {
  width: 100%;
  height: 0;
  border: 0;
  border-top: solid 1px #555;
  margin: 1.25rem 0;
}

@media (min-width: 600px) {
  .Cover-heading {
    font-size: 2.25rem;
  }
}
