.Button {
  display: block;
  padding: 0.75rem 1rem;
  border: solid 2px #bbb;
  line-height: 1.25;
  font-size: 1em;
  font-weight: bold;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  text-align: center;
  cursor: pointer;
  color: #333;
  background-color: #fff;
  transition: background-color 0.2s ease-in, border-color 0.1s ease-in;
}

.Button:hover,
.Button:active,
.Button:focus {
  color: #000;
  background-color: #ddd;
  border-color: #999;
}

.Button:disabled {
  cursor: default;
  color: #555;
  background-color: #fff;
  border-color: #bbb;
  opacity: 0.8;
}
