.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.Container-centred {
  justify-content: center;
  align-items: center;
}

@media (min-width: 400px) {
  .Container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 600px) {
  .Container {
    padding-left: 8.1%;
    padding-right: 8.1%;
  }
}

@media (min-width: 800px) {
  .Container {
    padding-left: 16.4%;
    padding-right: 16.4%;
  }
}
