.About {
  background-color: #f5d2d3;
}

.About-links {
  display: flex;
  list-style: none;
  margin: 0 0 2rem;
  padding: 0;
}

.About-links > li {
  display: flex;
  margin: 0 2rem;
}

.About-link {
  border-width: 0;
}

.About-link > span {
  border-bottom: solid 0 #404040;
}

.About-link:hover > span,
.About-link:active > span,
.About-link:focus > span {
  border-width: 1px;
}

.About-link-icon {
  margin-right: 0.5rem;
}
